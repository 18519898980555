import { useAtom, useAtomValue } from 'jotai';

import { CLUB_STATUSES } from '@squadnet/universal-constants';

import { useMatch } from '@/hooks/useNext';
import { clubQueryVariablesAtom, currentClubAtom } from '@/state/currentClub';

export const useClub = () => {
  const matched = useMatch<{ slug: string }>('/club/:slug/:routes*');
  const [variables, setVariables] = useAtom(clubQueryVariablesAtom);
  const { loading, ...rest } = useAtomValue(currentClubAtom);
  const clubSlug = variables.slug ?? matched?.params?.slug ?? '';

  return {
    clubSlug,
    clubUrl: `/club/${clubSlug}`,
    ...rest,
    subscribed: rest?.summary?.plan?.id,
    loading,
    verified: rest.club?.status === CLUB_STATUSES.VERIFIED,
    refetch: () => {
      setVariables({
        ...(variables.slug ? { slug: variables.slug } : {}),
        timestamp: new Date().getTime(),
      });
    },
    updateSlug: (slug: string | null) => {
      if (slug) {
        if (slug !== variables?.slug) {
          setVariables({ slug });
        }
      } else {
        setVariables({});
      }
    },
  };
};
