import { usePathname, useRouter } from 'next/navigation';
import { match } from 'path-to-regexp';
import { useMemo } from 'react';

export const useMatch = <T>(pattern: string) => {
  const pathname = usePathname() || '';
  const matched = useMemo(() => {
    const fn = match<T & Record<string, any>>(pattern, { decode: decodeURIComponent });
    try {
      return fn(pathname) as { params: T };
    } catch (_) {
      return false as unknown as { params: T };
    }
  }, [pathname]);
  return matched;
};

export const useNavigate = () => {
  const { push } = useRouter();
  return (url: any) => {
    push(url);
  };
};
