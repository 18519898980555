import { withCurrentClub } from '@squadnet/frontend-shared-states';

import { client } from '@/services/graphql/client';

export { clubQueryVariablesAtom } from '@squadnet/frontend-shared-states';

export const {
  clubAtom,
  clubSummaryAtom,
  clubQueryAtom,
  clubSummaryQueryAtom,
  clubCustomizationAtom,
  clubCustomizationQueryAtom,
  currentClubAtom,
} = withCurrentClub({ client });
